import React from 'react'
import QNA from './_qna'
import FaqSection from './_faqSection'

export const ManagementFaq = props => (
  <FaqSection id={props.id}>
    <QNA question=" How many annotations per hour (or other time unit) are typical? ">
      That depends on many factors, notably how hard the task is and how good
      your annotators are. <br />
    </QNA>

    <QNA question=" Does LightTag provide analytics about the performance of individual annotators ? ">
      Yes!
    </QNA>
  </FaqSection>
)
