import React from 'react'
import QNA from './_qna'
import { Link } from 'gatsby'
import FaqSection from './_faqSection'
import suggestions from './suggestions.gif'

export const SuggestionsFAQ = props => (
  <FaqSection sectionName="Automation & AI">
    <QNA question=" Can LightTag learn from our annotators and provide suggestions automatically ? ">
      Yes! <br />
      <img
        src={suggestions}
        alt="LightTag's suggestions in action"
        width="100%"
      />
      LightTag's payed offerings include machine learning models that will learn
      from your annotators as they work and provide suggestions. <br />
      We provide two models, the first is based on multi-armed-bandit algorithms
      and provides high precision suggestions fast. <br />
      We additionally offer a deep learning model which generalizes to unseen
      examples and leverages context. This feature is in beta{' '}
      <Link to="/contact/">contact us</Link> if you'd like to join the beta.
    </QNA>

    <QNA question=" We have a pre-existing model/dictionary/regular expressions that we want to use as suggestions to our annotators. Can we do that with LightTag ? ">
      Yes. You can upload suggestions to LightTag via the API.{' '}
      <a href="https://lighttag.io/guide/in-depth/suggestions.html">Docs</a>.{' '}
      <br />
      When you define a task, you can specify which source of suggestions
      LightTag should use. LightTag will present these suggestions to your
      annotators and record which suggestions they accept or reject.
    </QNA>
    <QNA question=" Can we use the model's that LightTag learned instead of building our own ? ">
      Yes! <br />
      We can expose the models that LightTag learns via an API which you can
      apply on your unlabeled text. <Link to="/contact"> Contact Us</Link> for
      details.
    </QNA>
    <QNA question=" Does LightTag support Active Learning ? ">
      It depends on how you define Active Learning. LightTag certainly learns
      from your annotators as they work reducing their workload and creating a
      useable model. However we don't use "by the book" Active Learning. We've
      written bout why{' '}
      <Link to="blog/active-learning-optimization-is-not-imporvement/">
        Active Learning for NLP{' '}
      </Link>{' '}
      isn't the best idea. <br />
    </QNA>
  </FaqSection>
)
