import React from 'react'
import QNA from './_qna'
import { Link } from 'gatsby'
import FaqSection from './_faqSection'

export const PricingFaq = props => (
  <FaqSection sectionName="Pricing">
    <QNA question=" We're a poor startup, can we have LightTag free until we grow ? ">
      Probably not free, but we're happy to work with you and see you grow.{' '}
      <Link to="/contact">Talk to us</Link>
    </QNA>

    <QNA question=" LightTag's SaaS pricing is for monthly active user. What is the definition of an active user ? ">
      LightTag's pricing is a pay-for-what-you use model, designed to let you
      scale your annotation efforts up or down as needed. An active user is a
      user account that has submitted annotations during the calendar month.{' '}
      <br />
      For example, Alice and Bob start using LightTag in January and both submit
      work. Then their were two monthly active users. <br />
      In February, Alice continued working but Bob went skiing and submitted no
      annotations. In February their was one active user. <br />
      In March, the team decided to double down on labeling and brought in
      Charles and Danny who both submitted work along with Alice and Bob. In
      march their were four active users.
    </QNA>
  </FaqSection>
)
