import React from 'react'
import QNA from './_qna'
import FaqSection from './_faqSection'

export const TeamFAQ = props => (
  <FaqSection>
    <QNA question=" We want to ensure data quality by having multiple people label the same document. Can LightTag help us do that ?">
      Yes! <br />
      You can tell LightTag you want n=
      {'{1,2,3..100}'} annotators to work on each example. Every time a labeler
      logs in, LightTag will assign them the next document to label based on
      what you've defined. <br />
      Your labelers never have to search for a particular file, and you don't
      have to manage who needs to do what work. LightTag does it for you
      automatically.
    </QNA>

    <QNA question=" We need to divide our labelers into different teams and ensure that team A only works on data X. Can LightTag help ? ">
      You can define teams in LightTag. When you you define a task, you can
      specify which teams are allowed to work on it.
      <br /> For example, if you had a team of Chinese lawyers and another team
      of Irish doctors, you could divide them into two teams and LightTag would
      ensure that only Chinese lawyers work on chinese legal texts, while Irish
      doctors would only work on Gaelic radioligy reports.
    </QNA>
  </FaqSection>
)
