import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const FaqSection = props => (
  <Grid item xs={12} id={props.id}>
    <Typography variant="h3" gutterBottom align="left">
      {props.sectionName}
    </Typography>

    {props.children}
  </Grid>
)
export default FaqSection
