import React from 'react'
import QNA from './_qna'
import { Link } from 'gatsby'
import annotationImg from '../../images/faq/annotation.png'
import FaqSection from './_faqSection'
import classificationExample from './classify.gif'
import relationsExample from './relations.gif'

export const CoreFeaturesFaq = props => (
  <FaqSection title="Core Features">
    <QNA question="Does LightTag work with RTL languages such as Arabic and Hebrew ? ">
      Yes!
    </QNA>

    <QNA question=" Does LightTag work with CJK languages  ? ">Yes!</QNA>

    <QNA question=" Does LightTag support document classification e.g. labeling an entire document instead of individual words ?   ">
      Yes! You can define a task to classify individual examples. You can
      specify if you'd like exactly one class applied to a document or multiple
      classes. <br />
      For example, you can specify a task to label product reviews as Praise,
      Insult or Unknown. <br />
      <img src={classificationExample} width="100%" />
      Alternatively, you could specify a task to label recipes as Dairy,
      Vegetarian, Kosher and Spicy.
    </QNA>

    <QNA question=" Can we annotate subwords and multi word expressions ?  ">
      Yes! Sometimes a picture is worth a thousand words. <br />
      <img
        src={annotationImg}
        alt="Subwords, multi word expressions"
        width="100%"
      />
      LightTag makes no assumptions about how your data is tokenized, you can
      easily label subwords, or multiple phrases.
    </QNA>

    <QNA question=" Does LightTag support nested annotations ? ">
      We've decided not to support this feature directly. You can achieve the
      same thing using our relationship annotation features, and we think that
      is the right way to annotate. <br />
      LightTag helps teams prepare their data for machine learning, and the bulk
      of machine learning algorithms can't output "nested annotations". Our job
      is to make it easy for you to take your annotations and plug them in a
      model, so it doesn't make sense to support something that doesn't align
      with that. <br />
      With that in mind, we do support{' '}
      <Link to="relations">annotating relationships </Link>
    </QNA>

    <QNA question=" Do you support relationships, coreference structures  phrase structure grammars or dependency grammars ? ">
      Yes! LightTag supports relationship annotation via a drag and drop
      interface. For the non-linguists, you can drag and drop annotations and
      define the way they relate to each other. <br />
      For the Linguists out there, LightTag supports any relationship annotation
      you can express as a tree (but not a DAG) including phrase structure
      grammars (with non-terminals that don't appear in the text) as well as
      dependency style annotations.
      <img src={relationsExample} alt="Annotating relationships" width="100%" />
    </QNA>
  </FaqSection>
)
