import React from 'react'
import Layout from '../../components/layout'
import { CoreFeaturesFaq } from './_coreFeatures'
import { PricingFaq } from './_pricing'
import { SuggestionsFAQ } from './_suggestions'
import { ManagementFaq } from './_management'
import { PrivacyAndDataFAQ } from './_privacyAndData'
import { TeamFAQ } from './_team'
import { Container, Grid } from '@material-ui/core'

const FAQ = props => (
  <Layout>
    <Container>
      <Grid container spacing={4} alignItems="center" justify="center">
        <CoreFeaturesFaq />
        <TeamFAQ />
        <PrivacyAndDataFAQ />
        <PricingFaq />
        <SuggestionsFAQ />
        <ManagementFaq />
      </Grid>
    </Container>
  </Layout>
)

export default FAQ
