import React from 'react'
import QNA from './_qna'
import { Link } from 'gatsby'
import FaqSection from './_faqSection'

export const PrivacyAndDataFAQ = props => (
  <FaqSection sectionName="Data & Privacy">
    <QNA question="Does it come on premise ?">Yes it does</QNA>

    <QNA question=" What is your data policy? ">
      In a nutshell, your data is yours , we don't touch it, use it, sell it or
      see it. <br />
      The details are in our <Link to="/legal/privacy">
        privacy policy.
      </Link>{' '}
      <br />
    </QNA>

    <QNA question=" How is Our Data Stored ? ">
      Users of our community version have their data stored on their local
      machines, never hitting our servers. <br />
      Users of our SaaS offering have their data stored in a database on AWS.{' '}
      <br /> Every SaaS customer's data is stored on a seperate and private
      database <br />
      Our On-premise customers naturally host their data on premise.
    </QNA>

    <QNA question=" Does LightTag backup our data ? ">
      Of course! LightTag takes autoamtic daily snapshots of your data and
      annotations. These snapshots are encrypted and stored for seven days.
      Longer retention periods are available.
    </QNA>
  </FaqSection>
)
