import React from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'

const QNA = props => {
  const slug = props.question
    .toLowerCase()
    .replace(/[^\w- ]+/g, '')
    .trim()
    .replace(/ /g, '-')

  return (
    <Grid item xs={10} md={12} style={{ marginBottom: '2rem' }}>
      <Typography variant="h6" gutterBottom>
        {props.question}
      </Typography>
      <Typography style={{ textAlign: 'left' }}>{props.children}</Typography>
      <Divider style={{ marginTop: '1rem' }} />
    </Grid>
  )
}

export default QNA
